import React from 'react';
import styled from 'styled-components';
import { dark_gray, darkest_gray, off_black } from '../../styles/emit-styles/ColorScheme';
import { InfoCardStyled } from '../../styles/emit-styles/CardStyles';

const Value = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: ${off_black};
`;

const Label = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: ${darkest_gray};
`;

const Caption = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${darkest_gray};
`;

const Note = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: ${dark_gray};
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: baseline;
`;

const InfoCard = ({ valuePrefix, value, label, caption, subcaption, height, width, minWidth, maxWidth, id }) => {
  return (
    <InfoCardStyled
      id={id}
      height={height}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      disableSelection
      data-testid="info-card-container-testid"
    >
      <Caption id={`${id}-caption`} data-testid="info-card-caption-testid">{caption}</Caption>
      <TitleWrapper id={`${id}-wrapper`}>
        <Value id={`${id}-value`} data-testid="info-card-value-testid">{valuePrefix}{value}</Value>
        {label && <Label id={`${id}-label`} data-testid="info-card-label-testid">{label}</Label>}
      </TitleWrapper>
      {subcaption && <Note id={`${id}-subcaption`} data-testid="info-card-subcaption-testid">{subcaption}</Note>}
    </InfoCardStyled>
  );
};

export default InfoCard;