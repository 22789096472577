import React from 'react';
import PdfHeader from './PdfHeader';
import PdfFooter from './PdfFooter';
import PdfInfoCard from './PdfInfoCard';
import { cngWarningTooltipCopy, compileAvoidedEmissionsByDrivetrainData, compileAvoidedEmissionsViewGraphData, compileDistanceBreakdownByAltDrivetrainData, compileDistanceBreakdownByDrivetrainData, compileFleetCompositionCardData, compileFleetMileageBreakdownData, compileGeneratedEmissionsByDrivetrainData, compileGeneratedEmissionsViewGraphData, compileHomeViewGraphData, FormatData } from './UtilityFunctions';
import { Page, Text, View, Document, StyleSheet, Link } from '@react-pdf/renderer';
import { altDrivetrains, combinedEmissionsBars, fleetCondensedDrivetrains, fleetDrivetrains, UNITS_GALLONS, UNITS_GRAMS, UNITS_POUNDS, UNITS_TONS } from './HelperConstants';
import PdfGraphCard from './PdfGraphCard';
import { pageStyle } from '../../styles/emit-styles/PdfStylesheet';
import PdfDistributionCard from './PdfDistributionCard';
import { blue_green, darkest_gray, dark_gray } from '../../styles/emit-styles/ColorScheme';

const styles = StyleSheet.create({
  page: pageStyle,
  distributionCardColumn: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16
  },
  distributionCardRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  sectionHeader: {
    fontSize: 14,
    color: darkest_gray,
    fontWeight: 500
  },
  sectionBody: {
    fontSize: 12,
    color: dark_gray,
    lineHeight: 1.2
  },
  sectionBodySpaceAbove: {
    fontSize: 12,
    color: dark_gray,
    lineHeight: 1.2,
    marginTop: 4
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16
  },
  filterColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    width: 300
  },
  filterLabel: {
    fontSize: 12,
    color: darkest_gray
  },
  bold: {
    fontWeight: 600
  },
  italic: {
    fontStyle: 'italic'
  },
  graphAndChartContainer: {
    display: 'flex',
    gap: 16,
    marginBottom: 16,
    width: '100%'
  },
  infoCardContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 16
  },
  infoCardRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 16,
    width: '100%'
  },
  infoCardRowForTwo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 26,
    width: '100%'
  }, 
  customLink: {
    color: blue_green
  },
  aboutText: {
    fontSize: 12,
    color: dark_gray,
    marginVertical: 4
  },
  section: {
    marginBottom: 16
  },
  infoAndDistributionCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
});

export default function PdfReport({ displayInLbs, emissionsSummary, graphData, selectedVehicleClasses, vehicleClasses, group, viewDataByMonth, dataDisplayCheck, handleNoDataMessageText, isTd, hasAltFuels, methodology, dbDisplayName }) {
  if (emissionsSummary && graphData) {
    const units = displayInLbs ? UNITS_POUNDS : UNITS_TONS;

    let emissions = emissionsSummary.ghgTons;
    let emissionReductions = emissionsSummary.ghgTonsSaved;
    let costSavings = emissionsSummary.fuelCostSaved;
    let fuelGallonsSaved = emissionsSummary.fuelGallonsSaved;
    let ghg = emissionsSummary.ghgTons;
    let co2 = emissionsSummary.CO2Tons;
    let ghgSaved = emissionsSummary.ghgTonsSaved;
    let CO2Saved = emissionsSummary.CO2TonsSaved;
    let ch4 = emissionsSummary.ch4Tons;
    let bioCO2 = emissionsSummary.bioCO2Tons;
    let ch4Saved = emissionsSummary.ch4TonsSaved;
    let bioCO2Saved = emissionsSummary.bioCO2TonsSaved;
    if (units === UNITS_POUNDS) {
      emissions = emissionsSummary.ghgLbs;
      emissionReductions = emissionsSummary.ghgLbsSaved;
      ghg = emissionsSummary.ghgLbs;
      co2 = emissionsSummary.CO2Lbs;
      ghgSaved = emissionsSummary.ghgLbsSaved;
      CO2Saved = emissionsSummary.CO2LbsSaved;
      ch4 = emissionsSummary.ch4Lbs;
      ch4Saved = emissionsSummary.ch4LbsSaved;
      bioCO2 = emissionsSummary.bioCO2Lbs;
      bioCO2Saved = emissionsSummary.bioCO2LbsSaved;
    }

    const hasReductionData = emissionsSummary.ghgTonsSaved !== 0;
    const hasCngVehicles = emissionsSummary.cng > 0;

    const combinedEmissionsData = compileHomeViewGraphData(graphData, viewDataByMonth, false, true);
    const fleetCompositionCardData = compileFleetCompositionCardData(emissionsSummary);
    const fleetMileageBreakdownData = compileFleetMileageBreakdownData(emissionsSummary);

    const generatedEmissionsData = compileGeneratedEmissionsViewGraphData(graphData, viewDataByMonth, false, true);
    const generatedEmissionsByDrivetrainData = compileGeneratedEmissionsByDrivetrainData(emissionsSummary, units);
    const distanceBreakdownByDrivetrainData = compileDistanceBreakdownByDrivetrainData(emissionsSummary);

    const avoidedEmissionsGraphData = compileAvoidedEmissionsViewGraphData(graphData, viewDataByMonth, false, true);
    const avoidedEmissionsByDrivetrainData = compileAvoidedEmissionsByDrivetrainData(emissionsSummary, units);
    const distanceBreakdownByAltDrivetrainData = compileDistanceBreakdownByAltDrivetrainData(emissionsSummary);

    const selectedClassesText = () => {
      if (selectedVehicleClasses.length === vehicleClasses.length) {
        return 'All Classes';
      } else {
        return selectedVehicleClasses.join(', ');
      }
    }

    const formatDateRangeStrings = (returnBeginDate) => {
      let { dateRange } = emissionsSummary;
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      if (dateRange) {
        let split = dateRange.split('-');
        return new Date(returnBeginDate ? split[0] : split[1]).toLocaleDateString('en-US', options);
      }
    }

    const aboutMethodologyCopy = (methodology) => {
      if(methodology.value === 'moves3') {
        return "The emissions calculations for this product are calculated for CO, CO₂, NOₓ, PM2.5, and PM10 based on coefficients publicly available from the EPA's MOVES3 model and the Argonne National Laboratory’s AFLEET tool."
      } else {
        return "The emissions calculations for this product are calculated for CO₂, CH₄, NOₓ, and BioCO₂ (Biofuel CO₂) based on coefficients publicly available through the Greenhouse Gas Protocol."
      }
    }

    const infoCardHeight = 80;
    const graphCardHeight = 250;
    const infoCardMinWidth = 145;
    const infoCardMaxWidth = 160;
    // Need to hardcode these due to the way the react-pdf-charts library interfaces with recharts (we can't use the react-pdf styling like 'auto' or '100%', the SVG conversion requires a numeric value)
    const graphCardWidth = 562;
    const detailDistributionCardWidth = 273;
    const summaryDistributionCardWidth = 240;

    return (
      <Document>
        <Page size='LETTER' orientation='portrait' style={styles.page}>
          <PdfHeader dbDisplayName={dbDisplayName} />
          <View id='Summary'>
            <View style={styles.section}>
              <Text style={styles.sectionBody}>
                This report summarizes your fleet emissions from {formatDateRangeStrings(true)} to {formatDateRangeStrings(false)}. Emit tracks your emissions based on your daily driving and the fuel type for each vehicle. For additional information on your emissions by vehicle, please go to your <Link style={styles.customLink} src='https://dashboard.sawatchlabs.com/'>Sawatch Labs dashboard</Link> to view details in Emit.
              </Text>
            </View>
            <View style={styles.filterSection}>
              <View style={styles.filterColumn}>
                <Text style={styles.filterLabel}><Text style={styles.bold}>Report Date:</Text> {new Date().toLocaleDateString()}</Text>
                {/* TODO: Once methodology functionality exists, update this value so it reflects the current setting */}
                <Text style={styles.filterLabel}><Text style={styles.bold}>Methodology:</Text> {methodology.label}</Text>
              </View>
              <View style={styles.filterColumn}>
                <Text style={styles.filterLabel}><Text style={styles.bold}>Group:</Text> {group}</Text>
                <Text style={styles.filterLabel}><Text style={styles.bold}>Vehicle Classes:</Text> {selectedClassesText()}</Text>
              </View>
            </View>
            {dataDisplayCheck('home') === false
              ?
              <Text style={styles.sectionBodySpaceAbove}>{handleNoDataMessageText('home', 'pdfReport')}</Text>
              :
              <>
                <View style={styles.graphAndChartContainer}>
                  <PdfGraphCard
                    height={graphCardHeight}
                    width={graphCardWidth}
                    title='Monthly Emissions'
                    data={combinedEmissionsData}
                    barInfo={combinedEmissionsBars}
                    yAxisLabel={`GHG (${units})`}
                    xAxisLabel={viewDataByMonth ? null : 'Week Starting'}
                    hasReductionData={hasReductionData}
                    displayInLbs={displayInLbs}
                    // including these settings here as a possible nice-to-have for the future
                    // if we want add in additional functionality for the PDF graph to respect the current product settings, additional work would be needed,
                    // but this is one less step to take to make that happen.
                    viewDataByMonth={viewDataByMonth}
                    showDiscreteData={true}
                    splitByDrivetrain={false}
                    combinedView
                  />
                  <View style={styles.infoAndDistributionCardContainer}>
                    <View style={styles.infoCardContainer}>
                      <View style={styles.infoCardRow}>
                        <PdfInfoCard
                          value={FormatData('emissions', emissions)}
                          caption='Generated GHG Emissions'
                          label={units}
                          height={infoCardHeight}
                          width={infoCardMinWidth}
                        />
                        <PdfInfoCard
                          value={FormatData('emissionReductions', emissionReductions, hasAltFuels)}
                          caption='Avoided GHG Emissions'
                          label={units}
                          height={infoCardHeight}
                          width={infoCardMinWidth}
                        />
                      </View>
                      <View style={styles.infoCardRow}>
                        <PdfInfoCard
                          value={FormatData('fuelCostSaved', costSavings, hasAltFuels)}
                          caption='Total Fuel Savings'
                          height={infoCardHeight}
                          width={infoCardMinWidth}
                        />
                        <PdfInfoCard
                          value={FormatData('fuelGallonsSaved', fuelGallonsSaved, hasAltFuels)}
                          caption='Total Fuel Reduction'
                          label={UNITS_GALLONS}
                          height={infoCardHeight}
                          width={infoCardMinWidth}
                        />
                      </View>
                    </View>
                    <View style={styles.distributionCardColumn}>
                      <PdfDistributionCard
                        title='Fleet Composition'
                        subtitle='All Vehicles'
                        height={infoCardHeight}
                        width={summaryDistributionCardWidth}
                        dataTypes={fleetDrivetrains}
                        data={fleetCompositionCardData}
                        displayInLbs={displayInLbs}
                        dataType='allVehicles'
                      />
                      <PdfDistributionCard
                        title='Mileage Breakdown'
                        subtitle='By Drivetrain'
                        height={infoCardHeight}
                        width={summaryDistributionCardWidth}
                        dataTypes={fleetCondensedDrivetrains}
                        data={fleetMileageBreakdownData}
                        displayInLbs={displayInLbs}
                        dataType='simplifiedMileage'
                      />
                    </View>
                  </View>
                </View>
              </>}
          </View>
          <View id='Generated Emissions' break>
            <Text style={styles.sectionHeader}>Generated Emissions</Text>
            {dataDisplayCheck('generated-emissions') === false
              ?
              <View style={styles.section}>
                <Text style={styles.sectionBodySpaceAbove}>{handleNoDataMessageText('generated-emissions', 'pdfReport')}</Text>
              </View>
              :
              <>
                <View style={styles.section}></View>
                <View style={styles.graphAndChartContainer}>
                  <PdfGraphCard
                    height={graphCardHeight}
                    width={graphCardWidth}
                    title='Monthly Emissions'
                    data={generatedEmissionsData}
                    barInfo={combinedEmissionsBars}
                    yAxisLabel={`GHG (${units})`}
                    xAxisLabel={viewDataByMonth ? null : 'Week Starting'}
                    displayInLbs={displayInLbs}
                    viewDataByMonth={true}
                    showDiscreteData={true}
                    splitByDrivetrain={false}
                  />
                  <View style={styles.distributionCardRow}>
                    <PdfDistributionCard
                      title='Mileage Breakdown'
                      subtitle='By Drivetrain'
                      height={infoCardHeight}
                      width={detailDistributionCardWidth}
                      dataTypes={fleetDrivetrains}
                      data={distanceBreakdownByDrivetrainData}
                      displayInLbs={displayInLbs}
                      dataType='allMileage'
                    />
                    <PdfDistributionCard
                      title='Cumulative Emissions'
                      subtitle='By Drivetrain'
                      height={infoCardHeight}
                      width={detailDistributionCardWidth}
                      dataTypes={fleetDrivetrains}
                      data={generatedEmissionsByDrivetrainData}
                      displayInLbs={displayInLbs}
                      dataType='generatedEmissions'
                    />
                  </View>
                </View>
                {methodology.value === 'moves3' 
                ?
                <View style={styles.infoCardContainer}>
                  <View style={styles.infoCardRow}>
                    <PdfInfoCard
                      value={FormatData('ghg', ghg)}
                      caption='GHG Emissions'
                      label={units}
                      subcaption='(Including GHG from kWh gen.)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                    <PdfInfoCard
                      value={FormatData('co2', co2)}
                      caption='Carbon Dioxide'
                      label={units}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                    <PdfInfoCard
                      value={FormatData('coLbs', emissionsSummary.coLbs)}
                      caption='Carbon Monoxide'
                      label={UNITS_POUNDS}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                  </View>
                  <View style={styles.infoCardRow}>
                    <PdfInfoCard
                      value={FormatData('noxLbs', emissionsSummary.noxLbs)}
                      caption='Nitrous Oxide'
                      label={UNITS_POUNDS}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                    <PdfInfoCard
                      value={FormatData('pm10Grams', emissionsSummary.pm10Grams)}
                      caption={`Particulate Matter 10\u00B5m`}
                      label={UNITS_GRAMS}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                    <PdfInfoCard
                      value={FormatData('pm25Grams', emissionsSummary.pm25Grams)}
                      caption={`Particulate Matter 2.5\u00B5m`}
                      label={UNITS_GRAMS}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                  </View>
                  </View>
                  :
                  <View style={styles.infoCardContainer}>
                    <View style={styles.infoCardRow}>
                      <PdfInfoCard
                        value={FormatData('ghg', ghg)}
                        caption='GHG Emissions'
                        label={units}
                        subcaption='(Including GHG from kWh gen.)'
                        height={infoCardHeight}
                        minWidth={infoCardMinWidth}
                        maxWidth={infoCardMaxWidth}
                        width={'100%'}
                      />
                      <PdfInfoCard
                        value={FormatData('co2', co2)}
                        caption='Carbon Dioxide'
                        label={units}
                        subcaption='(Tailpipe)'
                        height={infoCardHeight}
                        minWidth={infoCardMinWidth}
                        maxWidth={infoCardMaxWidth}
                        width={'100%'}
                      />
                      <PdfInfoCard
                        value={FormatData('ch4', ch4)}
                        caption='Methane'
                        label={units}
                        subcaption='(Tailpipe)'
                        height={infoCardHeight}
                        minWidth={infoCardMinWidth}
                        maxWidth={infoCardMaxWidth}
                        width={'100%'}
                      />
                    </View>
                    <View style={styles.infoCardRowForTwo}>
                      <PdfInfoCard
                        value={FormatData('noxLbs', emissionsSummary.noxLbs)}
                        caption='Nitrous Oxide'
                        label={UNITS_POUNDS}
                        subcaption='(Tailpipe)'
                        height={infoCardHeight}
                        minWidth={infoCardMinWidth}
                        maxWidth={infoCardMaxWidth}
                        width={'100%'}
                      />
                      <PdfInfoCard
                        value={FormatData('bioCO2', bioCO2)} 
                        caption='Biofuel CO₂'
                        label={units}
                        subcaption='(Tailpipe)'
                        height={infoCardHeight}
                        minWidth={infoCardMinWidth}
                        maxWidth={infoCardMaxWidth}
                        width={'100%'}
                      />
                    </View>

                  </View>
                }
              </>
            }
          </View>
          <View id='Avoided Emissions' break>
            <Text style={styles.sectionHeader}>Avoided Emissions</Text>
            {hasCngVehicles && 
              <Text style={styles.sectionBodySpaceAbove}>
                {cngWarningTooltipCopy(true)}
              </Text>
            }
            {dataDisplayCheck('avoided-emissions') === false
              ?
              <View style={styles.section}>
                <Text style={styles.sectionBodySpaceAbove}>{handleNoDataMessageText('avoided-emissions', 'pdfReport')}</Text>
              </View>
              :
              <>
                <View style={styles.section}></View>
                <View style={styles.graphAndChartContainer}>
                  <PdfGraphCard
                    height={graphCardHeight}
                    width={graphCardWidth}
                    title='Monthly Avoided Emissions'
                    data={avoidedEmissionsGraphData}
                    barInfo={combinedEmissionsBars}
                    yAxisLabel={`GHG (${units})`}
                    xAxisLabel={viewDataByMonth ? null : 'Week Starting'}
                    displayInLbs={displayInLbs}
                    viewDataByMonth={true}
                    showDiscreteData={true}
                    splitByDrivetrain={false}
                  />
                  <View style={styles.distributionCardRow}>
                    <PdfDistributionCard
                      title='Mileage Breakdown'
                      subtitle='By Drivetrain'
                      height={infoCardHeight}
                      width={detailDistributionCardWidth}
                      dataTypes={altDrivetrains}
                      data={distanceBreakdownByAltDrivetrainData}
                      displayInLbs={displayInLbs}
                      dataType='altFuelMileage'
                    />
                    <PdfDistributionCard
                      title='Avoided Emissions'
                      subtitle='By Drivetrain'
                      height={infoCardHeight}
                      width={detailDistributionCardWidth}
                      dataTypes={altDrivetrains}
                      data={avoidedEmissionsByDrivetrainData}
                      displayInLbs={displayInLbs}
                      dataType='avoidedEmissions'
                    />
                  </View>
                </View>
                {methodology.value === 'moves3' 
                ?
                <View style={styles.infoCardContainer}>
                  <View style={styles.infoCardRow}>
                    <PdfInfoCard
                      value={FormatData('ghgSaved', ghgSaved)}
                      caption='Avoided GHG Emissions'
                      label={units}
                      subcaption='(Including GHG from kWh gen.)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                    <PdfInfoCard
                      value={FormatData('co2Saved', CO2Saved)}
                      caption='Carbon Dioxide'
                      label={units}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                    <PdfInfoCard
                      value={FormatData('coLbsSaved', emissionsSummary.coLbsSaved)}
                      caption='Carbon Monoxide'
                      label={UNITS_POUNDS}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                  </View>
                  <View style={styles.infoCardRow}>
                    <PdfInfoCard
                      value={FormatData('noxLbsSaved', emissionsSummary.noxLbsSaved)}
                      caption='Nitrous Oxide'
                      label={UNITS_POUNDS}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                    <PdfInfoCard
                      value={FormatData('pm10GramsSaved', emissionsSummary.pm10GramsSaved)}
                      caption={`Particulate Matter 10\u00B5m`}
                      label={UNITS_GRAMS}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                    <PdfInfoCard
                      value={FormatData('pm25GramsSaved', emissionsSummary.pm25GramsSaved)}
                      caption={`Particulate Matter 2.5\u00B5m`}
                      label={UNITS_GRAMS}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                  </View>
                </View>
                :
                <View style={styles.infoCardContainer}>
                  <View style={styles.infoCardRow}>
                    <PdfInfoCard
                      value={FormatData('ghgSaved', ghgSaved)}
                      caption='Avoided GHG Emissions'
                      label={units}
                      subcaption='(Including GHG from kWh gen.)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                    <PdfInfoCard
                      value={FormatData('co2Saved', CO2Saved)}
                      caption='Carbon Dioxide'
                      label={units}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                    <PdfInfoCard
                      value={FormatData('ch4Saved', ch4Saved)}
                      caption='Methane'
                      label={units}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                  </View>
                  <View style={styles.infoCardRowForTwo}>
                    <PdfInfoCard
                      value={FormatData('noxLbsSaved', emissionsSummary.noxLbsSaved)}
                      caption='Nitrous Oxide'
                      label={UNITS_POUNDS}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                    <PdfInfoCard
                      value={FormatData('bioCO2Saved', bioCO2Saved)}
                      caption={`Biofuel CO₂`}
                      label={units}
                      subcaption='(Tailpipe)'
                      height={infoCardHeight}
                      minWidth={infoCardMinWidth}
                      maxWidth={infoCardMaxWidth}
                      width={'100%'}
                    />
                  </View>
                </View>
                }
              </>
            }
          </View>
          <View id='About' break>
            <View style={styles.section}>
              <Text style={styles.sectionHeader}>Emissions Reporting Data Source - {isTd ? "Telematics Data" : "Fuel Transaction Data"}</Text>
              <Text style={styles.aboutText}>
                {isTd ? 
                    "Your fleet telematics data was analyzed to generate the emissions for each vehicle." 
                    :
                    "Your fleet fuel card transaction data was analyzed to generate the emissions for each vehicle." 
                }
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.sectionHeader}>Emissions Report Inputs</Text>
              <Text style={styles.aboutText}><Text style={styles.italic}>Selected Methodology:</Text> {methodology.label}</Text>
              <Text style={styles.aboutText}>{aboutMethodologyCopy(methodology)}</Text>
              <Text style={styles.aboutText}>The coefficients are then adjusted to reflect the age and type of the vehicle, producing a value in grams per mile which are then recomposed into grams per gallon of fuel.</Text>
              <Text style={styles.aboutText}>Results are available for electric, gasoline, diesel and CNG-powered vehicles.</Text>
              <Text style={styles.aboutText}>For further questions, please contact Sawatch Labs at <Link style={styles.customLink} src={'mailto:info@sawatchlabs.com'}>info@sawatchlabs.com</Link>.</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.sectionHeader}>Sources:</Text>
              {methodology.value === 'moves3'
              ?
              <Text style={styles.aboutText}><Link style={styles.customLink} src='https://nepis.epa.gov/Exe/ZyPDF.cgi?Dockey=P100U8YT.pdf'>Greenhouse Gas Emissions from a Typical Passenger Vehicle</Link></Text>
              :
              <Text style={styles.aboutText}><Link style={styles.customLink} src='https://ghgprotocol.org/'>Greenhouse Gas Protocol</Link></Text>
              }
              <Text style={styles.aboutText}><Link style={styles.customLink} src='https://www.fueleconomy.gov/feg/pdfs/420r16010.pdf'>Light-Duty Automotive Technology, Carbon Dioxide Emissions, and Fuel Economy Trends</Link></Text>
              <Text style={styles.aboutText}><Link style={styles.customLink} src='https://greet.es.anl.gov/afleet_tool'>Argonne National Laboratory AFLEET Tool</Link></Text>
            </View>
          </View>
          <PdfFooter />
        </Page>
      </Document >
    );
  }
  else return null;
}