import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from '../../images/Info.svg';
import { ChartHover } from '../../styles/emit-styles/ChartStyles';
import { dark_gray, darkest_gray } from '../../styles/emit-styles/ColorScheme';
import { TRANSITION_LENGTH } from '../../styles/emit-styles/CardStyles';

const TooltipContainer = styled(ChartHover)`
    max-width: 330px;
    width: max-content;
    height: auto;
    z-index: 10;
    font-size: 14px;
    position: absolute;
    // NOTE these are currently hard coded since this tooltip is only being used for the CNG warning. We will need to improve the logic if it is used elsewhere.
    bottom: 24px;
    right: -48px;

    opacity: ${({ visible }) => visible ? 1 : 0};
    transition: opacity ${TRANSITION_LENGTH} ease;
    pointer-events: ${({ visible }) => visible ? 'auto' : 'none'}; // prevent container from interfering with selection of elements behind it (ie CNG warning blocking the distribution card hover above it)
`

const IconWrapper = styled.div`
    position: relative;
    fill: ${dark_gray};
    height: 16px;
    width: 16px;
    cursor: pointer;
    transition: fill ${TRANSITION_LENGTH} ease;
    
    &:hover {
        fill: ${darkest_gray};
    }
`;

const InfoTooltip = ({ description }) => {
    const [tooltipShown, setTooltipShown] = useState(false)

    return (
        <IconWrapper data-testid="infoTooltip-testid">
            <InfoIcon
                onMouseOver={() => setTooltipShown(true)}
                onMouseOut={() => setTooltipShown(false)}
                alt="Information Icon"
                data-testid="tooltipIcon-testid"
            />
            <TooltipContainer visible={tooltipShown} data-testid='tooltipHover-testid'>{description}</TooltipContainer>
        </IconWrapper>
    );
}

export default InfoTooltip;