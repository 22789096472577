import { bev_color, bev_color_hover, phev_color, phev_color_hover, cng_color, cng_color_hover, gasoline_color, gasoline_color_hover, diesel_color, diesel_color_hover, ice_fuels_color, ice_fuels_color_hover, alt_fuels_color, alt_fuels_color_hover, rust_red, rust_red_hover, blue_green, blue_green_hover } from '../../styles/emit-styles/ColorScheme';

export const UNITS_POUNDS = "lbs";
export const UNITS_TONS = "tons";
export const UNITS_GRAMS = "g";
export const UNITS_GALLONS = "gals";

export const AVOIDED_TABLE_TYPE = 'avoidedTable';
export const GENERATED_TABLE_TYPE = 'generatedTable';
export const PDF_TYPE = 'pdfReport';

export const altDrivetrains = [
  { id: "bev", label: "BEV", longLabel: "Battery Electric", color: bev_color, colorHover: bev_color_hover },
  { id: "phev", label: "PHEV", longLabel: "Plug-In Hybrid Electric", color: phev_color, colorHover: phev_color_hover },
  { id: "cng", label: "CNG", longLabel: "Compressed Natural Gas", color: cng_color, colorHover: cng_color_hover },
];

export const fleetDrivetrains = [
  { id: "gasoline", label: "Gasoline", color: gasoline_color, colorHover: gasoline_color_hover },
  { id: "diesel", label: "Diesel", color: diesel_color, colorHover: diesel_color_hover },
  ...altDrivetrains
];

export const fleetCondensedDrivetrains = [
  { id: "trad", label: "Traditional Fuel", color: ice_fuels_color, colorHover: ice_fuels_color_hover },
  { id: "alt", label: "Alternative Fuel", color: alt_fuels_color, colorHover: alt_fuels_color_hover },
];

export const combinedEmissionsBars = [
  {
    id: "generated",
    label: "Generated Emissions",
    color: rust_red,
    hoverColor: rust_red_hover,
  },
  {
    id: "avoided",
    label: "Avoided Emissions",
    color: blue_green,
    hoverColor: blue_green_hover,
    valueMultipler: -1
  }
];

export const emissionsByDrivetrainBars = [
  {
    id: "gasolineGenerated",
    label: "Gasoline",
    color: gasoline_color,
    hoverColor: gasoline_color_hover,
  },
  {
    id: "dieselGenerated",
    label: "Diesel",
    color: diesel_color,
    hoverColor: diesel_color_hover,
  },
  {
    id: "bevGenerated",
    label: "BEV",
    color: bev_color,
    hoverColor: bev_color_hover,
  },
  {
    id: "phevGenerated",
    label: "PHEV",
    color: phev_color,
    hoverColor: phev_color_hover,
  },
  {
    id: "cngGenerated",
    label: "CNG",
    color: cng_color,
    hoverColor: cng_color_hover,
  },
  {
    id: "bevAvoided",
    label: "BEV",
    color: bev_color,
    hoverColor: bev_color_hover,
    valueMultipler: -1
  },
  {
    id: "phevAvoided",
    label: "PHEV",
    color: phev_color,
    hoverColor: phev_color_hover,
    valueMultipler: -1
  }
];
