import React, { useEffect, useState } from "react";
import { Roles } from "../utility-components/UserUtils";
import * as S from '../../../styles/core-styles/MenuBar-styles';
import { filterProducts } from "./UtilityFunctions";

import {ReactComponent as HamburgerExpanded} from '../../../images/HamburgerExpanded.svg';
import {ReactComponent as HamburgerMinimized} from '../../../images/HamburgerMinimized.svg';
import {ReactComponent as HomeIcon} from '../../../images/Home.svg';
import {ReactComponent as DatabaseIcon} from '../../../images/Database.svg';
import {ReactComponent as DeveloperIcon} from '../../../images/Developer.svg';
import {ReactComponent as SettingsIcon} from '../../../images/Settings.svg';
import {ReactComponent as LocationEditIcon} from '../../../images/Location_Edit.svg'

export default function NavMenu(props) {
  const { user, onSelect, cdrManagementEnabled, products, isTelematics} = props;
  const [menuExpanded, setMenuExpanded] = useState(false);

  function MenuItem({menuExpanded, icon: Icon, description, iconType, onClick, name}) {
    return (
      <S.MenuRow
        onClick={onClick}
      >
        <S.IconHoverContainer>
          {iconType === "product" && 
            <S.ProductIconWrapper className="icon-wrapper">
              <S.ProductIcon id={`${name}-nav-icon`} src={Icon} alt={name}/>
            </S.ProductIconWrapper>
          }
          {iconType === "action" &&
            <S.ActionIconWrapper className="icon-wrapper">
              <Icon/>
            </S.ActionIconWrapper>
          }
        </S.IconHoverContainer>
        <S.ProductDescription menuExpanded={menuExpanded}>{description}</S.ProductDescription>
      </S.MenuRow>
    )
  }
  
  function generateProductRows(productList, menuExpanded, onSelect,toggleMenu,isTelematics) {
    const filteredProducts = filterProducts(true,productList,isTelematics);
    return filteredProducts.map(p => {
      return (
          <MenuItem
            key={p.product}
            name={p.display_name}
            menuExpanded={menuExpanded}
            iconType={"product"}
            icon={p.icon_url}
            description={p.short_description ? p.short_description : p.display_name}
            onClick={()=>{
              onSelect(p.product);
              toggleMenu(false)
            }}
          />
      )
    })
  }

  function Menu({menuExpanded, products, user, toggleMenu,isTelematics}) {
    const productRows = generateProductRows(products, menuExpanded, onSelect, toggleMenu, isTelematics);
    
    return (
      <S.MenuContainer menuExpanded={menuExpanded}>
        {products.length > 0 && // hide menu bar icons until products array has loaded
          <>
            <S.ProductIconsContainer className="iconsContainer">
              <MenuItem 
                menuExpanded={menuExpanded}
                iconType={"action"}
                icon={menuExpanded ? HamburgerExpanded : HamburgerMinimized} 
                description={"Minimize Menu"}
                onClick={() => toggleMenu(!menuExpanded)}
              />
              <S.RowScrollContainer>
                {productRows}
                <MenuItem 
                  menuExpanded={menuExpanded}
                  iconType={"action"}
                  icon={HomeIcon}
                  description={"All Products"}
                  onClick={()=>{onSelect("landing"); toggleMenu(false)}}
                />
              </S.RowScrollContainer>
            </S.ProductIconsContainer>
            {user.role >= Roles.FleetAdmin && // ensure user is at least fleet admin level to display the action items section & settings icon
              <S.ActionIconsContainer>
                <S.Line/>
                <S.RowScrollContainer>
                {user.role >= Roles.SuperAdmin && cdrManagementEnabled && 
                  <div data-testid={"swt-cdr-management-testid"}>
                    <MenuItem 
                      menuExpanded={menuExpanded}
                      iconType={"action"}
                      icon={DatabaseIcon}
                      description={"CDR Settings"}
                      onClick={()=>{onSelect("cdr-admin"); toggleMenu(false)}}
                    />
                  </div>
                }
                {products.some(p => p.product === "dev-tools" && p.available) && (user.role >= Roles.SuperAdminDeveloper) &&
                  <div data-testid={"nav-dev-tools-testid"}>
                    <MenuItem 
                    menuExpanded={menuExpanded}
                    iconType={"action"}
                    icon={DeveloperIcon}
                    description={"Dev Tools"}
                    onClick={()=>{onSelect("dev-tools"); toggleMenu(false)}}
                    />
                  </div>
                }
                {products.some(p => p.product === "admin" && p.available) && 
                  <div data-testid={"nav-admin-testid"}>
                    <MenuItem 
                      menuExpanded={menuExpanded}
                      iconType={"action"}
                      icon={SettingsIcon}
                      description={"Fleet Settings"}
                      onClick={()=>{onSelect("admin"); toggleMenu(false)}}
                    />
                  </div>
                }
                {products.some(p => p.product === "location-edit-tool" && p.available) && (user.role >= Roles.SuperAdminDeveloper) &&
                  <div data-testid={""}>
                    <MenuItem 
                      menuExpanded={menuExpanded}
                      iconType={"action"}
                      icon={LocationEditIcon}
                      description={"Location Edit Tool"}
                      onClick={()=>{onSelect("location-edit-tool"); toggleMenu(false)}}
                    />
                  </div>
                }
                </S.RowScrollContainer>
              </S.ActionIconsContainer>
            }
          </>
        }
      </S.MenuContainer>
    )
  }

  useEffect(() => {
    if (menuExpanded) {
      document.body.style.overflow = 'hidden'; // disable document-level scrolling when menu is expanded
      const handleEsc = (event) => {
        if (event.key === 'Escape') {
          setMenuExpanded(false); // Minimize menu and hide scrim if user pressed escape
        }
      };
      
      // Add event listener
      window.addEventListener('keydown', handleEsc);
      
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    } else {
      document.body.style.overflow = 'unset'; // re-enable document-level scrolling when menu is minimized
    }
  }, [menuExpanded]);

  const toggleMenu = (toggle) => {
    if (toggle !== menuExpanded) {
      setMenuExpanded(toggle);
    }
  };

  return (
    <>
      <S.Scrim
        menuExpanded={menuExpanded}
        onClick={()=>toggleMenu(false)}
      />
      <div className = {menuExpanded ? "nav-menu" : "nav-menu-minimized"}> {/*Styled components will refuse to render the transition, so we have to use this hybrid workaround instead.*/}
        <Menu
        menuExpanded={menuExpanded}
        products={products}
        user={user}
        toggleMenu={toggleMenu}
        isTelematics={isTelematics}
      />
      </div>
    </>
  );
}
