import React from 'react';
import styled from "styled-components";
import { blue_green, dark_gray, off_black } from '../../styles/emit-styles/ColorScheme';
import { InfoCardStyled } from '../../styles/emit-styles/CardStyles';

const AboutHeader = styled.div`
    font-size: 24px;
    font-weight: 500;
    color: ${off_black};
    margin-bottom: 8px;
`;

const AboutSubHeader = styled.div`
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    line-height: 35px;
    color: ${off_black};
`;

const AboutContainer = styled.div`
    max-width: 65%;
`;

const AboutText = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 8px;
    color: ${dark_gray};
`;

const Link = styled.a`
    color: ${blue_green};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

const AboutView = ({isTd, methodology}) => {
    
    function determineEmissionsText(methodology) {
        let text = '';
        if(methodology.value === 'moves3') {
            text = <div>The emissions calculations for this product are calculated for CO, CO<sub>2</sub>, NO<sub>x</sub>, PM2.5, and PM10 based on coefficients publicly available from the EPA's MOVES3 model and the Argonne National Laboratory’s AFLEET tool.</div>
        } else {
            text = <div>The emissions calculations for this product are calculated for CO<sub>2</sub>, CH<sub>4</sub>, NO<sub>x</sub>, and BioCO<sub>2</sub> (Biofuel CO<sub>2</sub>) based on coefficients publicly available through the Greenhouse Gas Protocol.</div>
        }
        return text;
    }

    return (
        <AboutContainer>
            <InfoCardStyled>
                <AboutHeader data-testid='about-data-source-header'>Emissions Reporting Data Source - {isTd ? "Telematics Data" : "Fuel Transaction Data"}</AboutHeader>
                <AboutText data-testid='about-data-source-copy'>{isTd ?
                    "Your fleet telematics data was analyzed to generate the emissions for each vehicle." 
                    :
                    "Your fleet fuel card transaction data was analyzed to generate the emissions for each vehicle." 
                }
                </AboutText>
            </InfoCardStyled>
            <InfoCardStyled>
                <AboutHeader>Emissions Report Inputs</AboutHeader>
                <AboutText data-testid='about-selected-methodology-value'><em>Selected Methodology:</em> {methodology.label}</AboutText>
                <AboutText data-testid='about-methodology-copy'>{determineEmissionsText(methodology)}</AboutText>
                <AboutText>The coefficients are then adjusted to reflect
                    the age and type of the vehicle, producing a value in grams
                    per mile which are then recomposed into grams per gallon of fuel.
                </AboutText>
                <AboutText>
                    Results are available for electric, gasoline, diesel and CNG-powered
                    vehicles.
                </AboutText>
                <AboutText> For further questions, please contact Sawatch Labs
                    at <Link href="mailto: info@sawatchlabs.com">info@sawatchlabs.com</Link>.
                </AboutText>
            </InfoCardStyled>
            <InfoCardStyled>
                <AboutSubHeader>Sources:</AboutSubHeader>
                {methodology.value === 'moves3'
                ?
                <Link
                    data-testid='about-methodology-link'
                    href="https://nepis.epa.gov/Exe/ZyPDF.cgi?Dockey=P100U8YT.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Greenhouse Gas Emissions from a Typical Passenger Vehicle
                </Link>
                :
                <Link
                    data-testid='about-methodology-link'
                    href="https://ghgprotocol.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Greenhouse Gas Protocol
                </Link>
                }
                <Link
                    href="https://www.fueleconomy.gov/feg/pdfs/420r16010.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Light-Duty Automotive Technology, Carbon Dioxide Emissions, and Fuel Economy Trends
                </Link>
                <Link
                    href="https://greet.es.anl.gov/afleet_tool"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Argonne National Laboratory AFLEET Tool
                </Link>
            </InfoCardStyled>
        </AboutContainer>
    )
}

export default AboutView;