import React from 'react';
import styled from 'styled-components';
import LogoImage from '../../images/emit-dark.png';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import { darkest_gray, off_black } from '../../styles/emit-styles/ColorScheme';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    gap: 16px;
`

const HeaderLogoWrapper = styled(NavLink)`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;
`

const HeaderLogo = styled.img`
    width: 100px;
    height: 100%;
    object-fit: contain;
`

const HeaderTextMain = styled.div`
    font-size: 30px;
    font-weight: 500;
    color:${off_black};
`

const HeaderTextSub = styled(HeaderTextMain)`
    color: ${darkest_gray};
    font-weight: 400;
    font-size: 28px;
`

const ProductHeader = ({fleetDisplayName}) => {
    return (
        <HeaderContainer>
            <HeaderLogoWrapper to={"/emit/home"}>
                <HeaderLogo src={LogoImage} />
            </HeaderLogoWrapper>
            <div>
                <HeaderTextMain>Emissions Report</HeaderTextMain>
                {fleetDisplayName && <HeaderTextSub data-testid='fleetDisplayName-testid'>{fleetDisplayName}</HeaderTextSub>}
            </div>
        </HeaderContainer>
    )
}

export default ProductHeader;