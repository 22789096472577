import React from 'react';
import styled from "styled-components";
import { blue_green, blue_green_hover, darkest_gray, light_gray, off_black, white } from "../../styles/emit-styles/ColorScheme";
import { NavLink } from "react-router-dom";
import { SMALL_RADIUS, TRANSITION_LENGTH } from "../../styles/emit-styles/CardStyles";
import ControlFilters from "./ControlFilters";
import { useRef, useState, useEffect } from "react";
import DateRangeControls from "./DateRangeControls";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as DownloadPdfIcon } from '../../images/FileDownload.svg';
import { ReactComponent as DateIcon } from '../../images/Calendar.svg';
import { ReactComponent as FilterIcon } from '../../images/Filter.svg';
import { FilterPopoverContainer } from "../../styles/emit-styles/Emit-styles";
import { buildDownloadFilename } from "./UtilityFunctions";

const BORDER_WIDTH = 2;
const BORDER_BOTTOM_PADDING = 4;

const NavContainer = styled.nav`
    width: 100%;
    min-width: 960px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${BORDER_WIDTH}px solid ${darkest_gray};
    font-weight: 400;
    padding-bottom: ${BORDER_BOTTOM_PADDING}px;
    font-size: 16px;
`;

const LinkContainer = styled.div`
    display: flex;
    gap: 12px;
`

const StyledLink = styled(NavLink)`
    transition: color ${TRANSITION_LENGTH} ease, border-bottom ${TRANSITION_LENGTH} ease, text-shadow ${TRANSITION_LENGTH} ease;
    text-decoration: none;
    color: ${darkest_gray};
    border-bottom: ${BORDER_WIDTH + 2}px solid transparent;
    margin-bottom: -${BORDER_WIDTH + BORDER_BOTTOM_PADDING + 6}px; // Negative margin to pull the border up
    padding-bottom: ${BORDER_WIDTH + 6}px;  // Add padding equal to the border width to maintain spacing

    &:hover {
        color: ${blue_green};
        border-bottom: ${BORDER_WIDTH + 2}px solid ${blue_green};
        text-shadow: 0 0 0.1px;
    }

    &.active {
        color: ${off_black};
        border-bottom: ${BORDER_WIDTH + 2}px solid ${off_black};
        position: relative;
        /* font-weight: 600; */
        text-shadow: 0 0 0.2px; // using text-shadow prevents bold from causing other elements to shift. kinda hacky but it works
    }
`;

const PDFDownloadButton = styled.a`
    min-width: ${({minWidth}) => minWidth ?? 0};
    width: ${({width}) => width ?? '80px'};
    height: ${({height}) => height ?? '30px'};
    border-radius: ${SMALL_RADIUS}px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    /* text-align: center; */
    letter-spacing: 0.25px;
    color: ${white};
    font-family: inherit;
    transition: background-color 0.2s ease;
    background-color: ${({disabled}) => disabled ? light_gray : blue_green};
    pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
    cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
    &:hover{
        background-color: ${blue_green_hover};
        color: ${white};
    }
`;

const ControlButton = styled.button`
    width: ${(props) => props.width ? props.width : '80px'};
    height: ${(props) => props.height ? props.height : '30px'};
    background-color: white;
    border: 2px solid ${blue_green};
    border-radius: ${SMALL_RADIUS}px;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    letter-spacing: 0.25px;
    color: ${blue_green};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;
    transition: border-color 0.2s ease, color 0.2s ease, background-color 0.2s ease;
    min-width: ${(props) => props.minWidth ? props.minWidth : 0};
    pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
    cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
    svg {
        transition: fill 0.2s ease;
    }

    ${(props) => props.active &&`
        background-color: ${blue_green_hover};
        color: ${white};
        border-color: transparent;
        svg {
            fill: ${white};
        }
        ` 
    }

    &:hover {
        background-color: ${blue_green_hover};
        color: ${white};
        border-color: transparent;
        svg {
            fill: ${white};
        }
    }

    ${({disabled}) => disabled && `
        color: ${light_gray};
        border-color: ${light_gray};
        svg {
            fill: ${light_gray};
        }
    `};
`;

export const ButtonPopoverWrapper = styled.div`
    position: relative;
`;

export const ControlButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row; 
    gap: 12px;
`;

const IconWrapper = styled.div`
  fill: ${({ fill }) => fill};

  height: 20px;
  width: 20px;
  margin-right: ${({ margin }) => margin || '2px'};
  display: flex;
  align-items: center;
`;


const EmitLink = ({ id, label }) => {
    return <StyledLink to={`/${id}`} id={id}>{label}</StyledLink>;
};

const ControlButtons = ({ dbDisplayName, dataLoading, beginDate, endDate, vehicleClasses, selectedVehicleClasses, group, groups, handleUnitChange, handleGroupChange, handleModelChange, handleClassChange, calcByFuel, displayInLbs, handleBeginDateChange, handleEndDateChange, minDate, maxDate, downloadUrl, methodology, setMethodology }) => {
    const [openFilterPopover, setOpenFilterPopover] = useState(false);
    const [openDateRangePopover, setOpenDateRangePopover] = useState(false);

    const filterRef = useRef(null);
    const dateRangeRef = useRef(null);

    useOutsideAlerter(filterRef, () => setOpenFilterPopover(false));
    useOutsideAlerter(dateRangeRef, () => setOpenDateRangePopover(false));

    function useOutsideAlerter(ref, cb) {
        useEffect(() => {
            function handleClickOutside(event) {
                let eventTargetsToIgnore = ['date-range-btn', 'filters-btn', 'filter-btn-icon', 'date-range-icon']
                if (ref.current && !ref.current.contains(event.target) && !eventTargetsToIgnore.includes(event.target.id)) {
                    cb()
                }
            }
    
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, cb]);
    }

    function handleFilterClick(e) {
        if (openDateRangePopover) setOpenDateRangePopover(false);
        setOpenFilterPopover(!openFilterPopover);
    }

    function handleDateRangeClick() {
        if (openFilterPopover) setOpenFilterPopover(false);
        setOpenDateRangePopover(!openDateRangePopover);
    }

    const location = useLocation();
    return (
        <ControlButtonsWrapper>
            {location.pathname === '/emit/home' && <PDFDownloadButton
                id={'pdf-download-button'}
                href={downloadUrl}
                download={buildDownloadFilename(dbDisplayName, 'pdfReport')}
                width='160px'
                disabled={dataLoading}
            >
                <IconWrapper fill={white}>
                    <DownloadPdfIcon />
                </IconWrapper>
                Download PDF
            </PDFDownloadButton>}
            <ButtonPopoverWrapper>
                <ControlButton
                    id='date-range-btn'
                    width='240px'
                    active={openDateRangePopover}
                    onClick={(e) => handleDateRangeClick(e)}
                    disabled={dataLoading}
                >
                    <IconWrapper fill={blue_green} margin='4px'>
                        <DateIcon id='date-range-icon'/>
                    </IconWrapper>
                    {(dataLoading) ? "Loading Dates" : `${beginDate?.toLocaleDateString("en-US")} to ${endDate?.toLocaleDateString("en-US")}`}
                </ControlButton>
                <FilterPopoverContainer ref={dateRangeRef} openPopover={openDateRangePopover} width='210px'>
                    <DateRangeControls
                        handleBeginDateChange={handleBeginDateChange}
                        handleEndDateChange={handleEndDateChange}
                        beginDate={beginDate}
                        endDate={endDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        setOpenDateRangePopover={setOpenDateRangePopover}
                    />
                </FilterPopoverContainer>
            </ButtonPopoverWrapper>
            <ButtonPopoverWrapper>
                <ControlButton
                    id='filters-btn'
                    active={openFilterPopover}
                    width='96px'
                    onClick={(e) => handleFilterClick(e)}
                    disabled={dataLoading}
                >
                    <IconWrapper fill={blue_green}>
                        <FilterIcon id='filter-btn-icon'/>
                    </IconWrapper>
                    Filters
                </ControlButton>
                <FilterPopoverContainer ref={filterRef} openPopover={openFilterPopover}>
                    <ControlFilters
                        vehicleClasses={vehicleClasses}
                        selectedVehicleClasses={selectedVehicleClasses}
                        group={group}
                        groups={groups}
                        calcByFuel={calcByFuel}
                        displayInLbs={displayInLbs}
                        handleClassChange={handleClassChange}
                        handleGroupChange={handleGroupChange}
                        handleModelChange={handleModelChange}
                        handleUnitChange={handleUnitChange}
                        setOpenFilterPopover={setOpenFilterPopover}
                        methodology={methodology}
                        setMethodology={setMethodology}
                    />
                </FilterPopoverContainer>
            </ButtonPopoverWrapper>
        </ControlButtonsWrapper>

    )
};


const NavBar = ({ vehicleClasses, selectedVehicleClasses, group, groups, handleUnitChange, handleGroupChange, handleModelChange, handleClassChange, calcByFuel, displayInLbs, handleBeginDateChange, handleEndDateChange, beginDate, endDate, minDate, maxDate, dataLoading, downloadUrl, dbDisplayName, methodology, setMethodology }) => {
    return (
        <NavContainer>
            <LinkContainer>
                <EmitLink id={'emit/home'} label={'Home'} />
                <EmitLink id={'emit/generated-emissions'} label={'Generated Emissions'} />
                <EmitLink id={'emit/avoided-emissions'} label={'Avoided Emissions'} />
                <EmitLink id={'emit/about'} label={'About'} />
            </LinkContainer>
            <ControlButtons
                vehicleClasses={vehicleClasses}
                selectedVehicleClasses={selectedVehicleClasses}
                group={group}
                groups={groups}
                calcByFuel={calcByFuel}
                displayInLbs={displayInLbs}
                handleClassChange={handleClassChange}
                handleGroupChange={handleGroupChange}
                handleModelChange={handleModelChange}
                handleUnitChange={handleUnitChange}
                handleBeginDateChange={handleBeginDateChange}
                handleEndDateChange={handleEndDateChange}
                beginDate={beginDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
                dataLoading={dataLoading}
                downloadUrl={downloadUrl}
                dbDisplayName={dbDisplayName}
                methodology={methodology}
                setMethodology={setMethodology}
            />
        </NavContainer>
    )
}

export default NavBar;