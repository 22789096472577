import React from 'react';
import styled from 'styled-components';
import InfoCard from './InfoCard';

const GridStyle = styled.div`
  display: grid;
  grid-template-columns: ${({ gridLayout }) => gridLayout || 'auto'};
  column-gap: 24px;
  width: 100%;
  justify-content: space-between;
`;

const CardGrid = ({ cardInfoArray, cardHeight, cardWidth, cardMinWidth, cardMaxWidth, gridLayout }) => {

  return (
    <GridStyle
      gridLayout={gridLayout}
    >
      {cardInfoArray.map((card, index) => (
          <InfoCard
            id={card.id}
            key={index}
            valuePrefix={card.valuePrefix}
            value={card.value}
            label={card.label}
            caption={card.caption}
            subcaption={card.subcaption}
            width={cardWidth}
            maxWidth={cardMaxWidth}
            minWidth={cardMinWidth}
            height={cardHeight}
          />
        ))}
    </GridStyle>
  );
};

export default CardGrid;