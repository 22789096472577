import React, { useMemo, useEffect, useRef, useState } from "react";
import { useTable, useSortBy, usePagination, useFlexLayout } from "react-table";
import styled from "styled-components/macro";
import { ReactComponent as SortArrowAsc } from '../../images/SortArrowAsc.svg';
import { ReactComponent as SortArrowDesc } from '../../images/SortArrowDesc.svg';
import { ReactComponent as SortArrowUnsorted } from '../../images/SortArrowUnsorted.svg';

import { FormatTableData } from "./UtilityFunctions";
import { dark_gray, darkest_gray, sawatch_blue, white } from "../../styles/emit-styles/ColorScheme";
import { MEDIUM_RADIUS } from "../../styles/emit-styles/CardStyles";

//This isn't a great way of doing styles but it's fully supported as an escape hatch for a situation like this
// It's only really acceptable if there's something you've got in your page where you can't/shouldn't modify the internal components of something you're importing but do know the classnames assigned to them
const TableContainer = styled.div`
  width: 100%;
  max-width: 2000px;
  min-width: 900px;
  
`;

const TableBody = styled.div`
  max-height: 482px;
  /* margin-top: 20px; */
  overflow: scroll;
  white-space: nowrap;
  text-align: left;
  border-radius: ${MEDIUM_RADIUS}px;

  thead {
    position: sticky;
    top: 0;
    z-index: 2;

    & th {
      background-color: ${sawatch_blue};
      color: ${white};
      font-size: 16px;
      border-spacing: 0;
      height: 64px;
      padding: 0 30px;
      text-align: left;
      margin: 0;
      white-space: pre;

      div span {
        cursor: pointer;
        user-select: none;
      }

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 3;
      }
    }
  }

  table {
    border-collapse: collapse;
  }

  .swt-emit-table-row {
    height: min-content;
    min-height: 50px;
    font-size: 14px;
    background-color: ${white};

    & td {
      padding: 5px 30px;
      white-space: wrap;

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: inherit;
      }
    }

    // CSS pseudo class for alternate row colors
    &:nth-child(odd) {
      background-color: rgb(245, 245, 245);
    }
  }

  .emit-table-search {
    height: 30px;
    margin-top: 5px;
  }
`;

const NoDataMessage = styled.p`
  font-size: 16px;
  color: ${dark_gray};
  white-space: nowrap;
  margin: 0;
  padding: 10px 0;
  text-align: center;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const PaginationButtons = styled.button`
  width: 65px;
  height: 20px;
  padding: 0 5px;
  border: 0.7px solid #979a9e;
  border-radius: 9px;
  background-color: ${white};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: center;
  color: #74787d;
  text-decoration: none;
  cursor: pointer;
  transition: all ease-in-out 0.2s;

  &:disabled {
    cursor: default;
    color: rgba(16, 16, 16, 0.3);
    border-color: rgba(16, 16, 16, 0.2);
  }

  &:hover:not([disabled]) {
    border-color: #262627;
    color: #262627;
    transform: scale(1.02);
    transition: all ease-in-out 0.2s;
  }
`;

const PaginationDetails = styled.span`
  padding: 0 5px;
  font-size: 12px;
  color: ${darkest_gray};
  letter-spacing: 0.1px;
`;

const IconWrapper = styled.span`
  margin-top: -16px;
  position: relative; // For absolute positioning of SVGs

  svg {
    height: 16px;
    width: 16px;
    fill: ${white};
    position: absolute; // Position SVGs on top of each other
    top: 0;
    left: 0;
    opacity: 0;       // Initially hide all SVGs
    transition: opacity 0.1s ease; // transition the opacity 'out' very quickly, to prevent crossfading
  }

  svg.active {
    opacity: 1;
    transition: opacity 0.5s ease; // transition the opacity 'in' a bit slower
  }
`;

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      display: 'flex',
      height: '100%'
    },
  },
]

function VehicleTable(props) {
  const { columns, data, noDataMessage } = props;
  const tableRef = useRef(null);
  const [pageIndex, setPageIndex] = useState(0);

  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout
      // This is the default widths for every column
      // width is used for both the flex-basis and flex-grow
      width: 100,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    rows,
    state,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 30,
        sortBy: [
          {
            id: "asset_id"
          }
        ]
      },
      pageIndex
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  useEffect(() => {
    tableRef.current.scrollTo({ top: 0 });
  }, [pageIndex]);

  useEffect(() => {
    setPageIndex(state.pageIndex);
  }, [state.pageIndex]);

  return (
    <TableContainer>
      <TableBody ref={tableRef}>
        <table {...getTableProps()} className="vehicle-table" data-testid="vehicles-table-container-testid">
          <thead id="emit-vehicle-table-headers">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id="emit-vehicle-table-header-row">
                {headerGroup.headers.map((column) => (
                  <th key={column.id}>
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps({ title: null }))}
                      {...column.getHeaderProps(headerProps)}
                      id="emit-vehicle-table-header"
                    >
                      <span
                        onMouseEnter={(e) => {
                          if (column['showToolTip']) {
                            column.showToolTip(true, e);
                          }
                        }}
                        onMouseLeave={(e) => {
                          if (column['showToolTip']) {
                            column.showToolTip(false, e);
                          }
                        }}
                        data-testid={`vehicle-table-${column.id}-header-testid`}
                      >
                        {column.render("Header")}
                      </span>
                      <IconWrapper
                        title={`Toggle sorting by ${column.Header}`}
                      >
                        <SortArrowDesc className={(column.isSorted && column.isSortedDesc && 'active') || null} />
                        <SortArrowAsc className={(column.isSorted && !column.isSortedDesc && 'active') || null} />
                        <SortArrowUnsorted className={(!column.isSorted && 'active') || null} />
                      </IconWrapper>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr className='swt-emit-table-row' {...row.getRowProps()} id="emit-vehicle-table-row">
                  {row.cells.map((cell, idx) => (
                    <td
                      key={`${cell.value}-${idx}`}
                      data-testid={`vehicle-table-${cell.column.id}-row-${index}-testid`}
                    >
                      <div
                        {...cell.getCellProps()}
                        {...cell.getCellProps(cellProps)}
                        id={cell.vin}
                        className={`emit-table-cell-${cell.column.id}`}
                      >
                        {FormatTableData(cell.column.id, cell.value, row.original, row.original.is_cng)}
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableBody>
      {rows.length < 1 ? (
        <NoDataMessage data-testid="vehicle-table-no-data-message-testid">
          {noDataMessage
            ? `${noDataMessage}`
            : 'No data to display. Try adjusting date or filters.'}
        </NoDataMessage>
      ) : (
        <Pagination>
          <PaginationButtons onClick={previousPage} disabled={!canPreviousPage}>
            {'Previous'}
          </PaginationButtons>

          <PaginationDetails>
            Page {pageIndex + 1} of {pageOptions.length}
          </PaginationDetails>

          <PaginationButtons onClick={nextPage} disabled={!canNextPage}>
            {'Next'}
          </PaginationButtons>
        </Pagination>
      )}
    </TableContainer>
  );
}

export default VehicleTable;