import React from 'react';
import styled from 'styled-components';
// import { dark_gray, darkest_gray, off_black } from '../styles/emit-styles/ColorScheme';
import { CardTitleWrapper, CardSubtitle, CardTitle, InfoCardStyled, CardBody, TRANSITION_LENGTH } from '../../styles/emit-styles/CardStyles';
import Search from './Search';
import ExcelDownloadButton from './ExcelDownloadButton';
import VehicleTable from './VehicleTable';
import { allVehiclesTableColumns, altFuelVehiclesTableColumns } from './TableHelpers';
import { AVOIDED_TABLE_TYPE, UNITS_POUNDS, UNITS_TONS } from './HelperConstants';
import { ChartHover } from '../../styles/emit-styles/ChartStyles';
import { useState } from 'react';
import InfoTooltip from './InfoTooltip';
import { cngWarningTooltipCopy } from './UtilityFunctions';

const EMISSIONS_RATIO_HEADER = 'Emission Ratio';
const EMISSIONS_RATIO_COPY = 'The emission ratio is the total emissions per mile for each vehicle.';
const MAX_HOVER_WIDTH = 280;

const Table = styled.div`
    width: 100%;
    height: 100%;
`;

const TableHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const TableBodyWrapper = styled.div`
    max-width: 50%;
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

const TableHeaderHoverContainer = styled(ChartHover)`
    position: absolute;
    ${({ cursorCoords }) => `left: ${cursorCoords.x}px; top: ${cursorCoords.y}px`};
    width: max-content;
    max-width: 240px;
    opacity: ${({ visible }) => visible ? 1 : 0};
    transition: opacity ${TRANSITION_LENGTH} ease;
    font-size: 14px;
`;


const TableCard = ({ title, subtitle, description, height, maxHeight, width, displayInLbs, tableType, dbDisplayName, group, beginDate, endDate, selectedVehicleClasses, vehicleClasses, mappedData, rawData, handleNoDataMessageText, setMatchingValues, hasCngVehicles, isTd, methodology }) => {

    const [showEmissionRatioToolTip, setShowEmissionRatioToolTip] = useState(false);
    const [cursorCoords, setCursorCoords] = useState({ x: 0, y: 0 });

    const handleShowEmissionRatioToolTip = (show, mouseEvent) => {
        const windowWidth = window.innerWidth;

        let x = mouseEvent.clientX + 20;
        let y = mouseEvent.clientY + 20;

        if (x + MAX_HOVER_WIDTH > windowWidth) {
            x = windowWidth - MAX_HOVER_WIDTH - 40; // Adjust x to keep hover within window
        }

        setCursorCoords({ x, y });
        setShowEmissionRatioToolTip(show);
    }


    const allVehiclesTableColumnsWithHovers = allVehiclesTableColumns(displayInLbs, isTd, methodology);

    let emissionsRatioHeader = allVehiclesTableColumnsWithHovers.find((vtc) => vtc.Header === EMISSIONS_RATIO_HEADER);
    if(emissionsRatioHeader) {
        emissionsRatioHeader.showToolTip = handleShowEmissionRatioToolTip;
    }

    const tableColumns = tableType === AVOIDED_TABLE_TYPE ?  altFuelVehiclesTableColumns(displayInLbs, isTd, methodology) : allVehiclesTableColumnsWithHovers;

    return (
        <InfoCardStyled
            height={height}
            maxHeight={maxHeight}
            width={width}
            align="start"
            gap='16px'
        >
            <TableHeaderWrapper>
                <TableBodyWrapper>
                    <CardTitleWrapper>
                        <CardTitle data-testid="table-card-title-testid">{title}</CardTitle>
                        <CardSubtitle data-testid="table-card-subtitle-testid">{subtitle}</CardSubtitle>
                        {(tableType === AVOIDED_TABLE_TYPE && hasCngVehicles) && <InfoTooltip description={cngWarningTooltipCopy()}/>}
                    </CardTitleWrapper>
                    <CardBody data-testid="table-card-description-testid">{description}</CardBody>
                </TableBodyWrapper>
                <ButtonWrapper>
                    <Search
                        setMatchingValues={setMatchingValues}
                        allValues={rawData}
                    />
                    <ExcelDownloadButton
                        width="118px"
                        label="Download"
                        beginDate={beginDate}
                        endDate={endDate}
                        emissionsData={mappedData}
                        group={group}
                        selectedVehicleClasses={selectedVehicleClasses}
                        vehicleClasses={vehicleClasses}
                        displayInLbs={displayInLbs}
                        dbDisplayName={dbDisplayName}
                        displayUnits={displayInLbs ? UNITS_POUNDS : UNITS_TONS}
                        csvType={tableType}
                        methodology={methodology}
                        cols={tableColumns}
                        isTd={isTd}
                    />
                </ButtonWrapper>
            </TableHeaderWrapper>
            <span style={{ position: 'fixed', top: 0, left: 0, zIndex: 3 }}>
                <TableHeaderHoverContainer
                    id={`emissions-ratio-tooltip`}
                    cursorCoords={cursorCoords}
                    visible={showEmissionRatioToolTip}
                >
                    {EMISSIONS_RATIO_COPY}
                </TableHeaderHoverContainer>
            </span>
            <Table>
                <VehicleTable
                    columns={tableColumns}
                    data={mappedData}
                    noDataMessage={handleNoDataMessageText('emissions-detail')}
                />
            </Table>
        </InfoCardStyled>
    );
};

export default TableCard;